<style lang="scss" scoped>
.tolttip-message {
  display: none !important;
}
/* Aca inica mi desarrollo  */
.tolttip-message {
  display: none !important;
}

.mx-input {
  height: 3em !important;
}

body {
  background: red !important;
}

.mx-datepicker-range > input {
  height: 3em !important;
}

#header {
  position: relative !important;
}

.btn-comercio {
  text-decoration: none;
  padding: 7.5px 10px;
  font-weight: 600;
  font-size: 1em;
  color: #ffffff;
  background-color: rgb(0, 38, 230);
  border-radius: 6px;
  width: 100%;
  border: 2px solid #0016b0;
}
.btn-comercio:hover {
  background-color: rgb(64, 204, 255);
  border: 2px solid rgb(64, 204, 255);
}

.title__login {
  color: #21458d;
  font-size: 30px;
  font-weight: 600;
}

.btn__ingresar {
  background: #4b92fc !important;
  border-color: #4b92fc !important;
  height: 45px;
  font-weight: 500 !important;
  font-size: 18px !important;
  letter-spacing: 1px;
}

.container__login {
  margin-top: 2%;
  // height: 85vh;
}
.label__input {
  font-size: 17px;
  font-weight: 500px;
  color: #000;
}
.p__input {
  margin-bottom: 0px;
  position: relative;
  top: -10px;
  font-size: 13px;
  color: #4d4d4d;
}

.page-not-available {
  background-color: #4b92fc;
  height: 100vh;
  width: 100%;
  color: #fff;
  font-size: 2em;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.page-not-available > p {
  text-align: center;
}
.page-not-available > p:nth-child(1) {
  font-size: 2.5em;
  font-weight: bold;
  text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.2);
}

.page-not-available > p:nth-child(2) {
  color: rgb(34, 59, 102);
}
/* Aca termina */
</style>
<template>
  <div>
    <CRow>
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
      ></loading>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Consulta Cobro Doble</strong>
          </CCardHeader>
          <CCardBody>
            <div class="form-group col-md-7">
              <label for="">Diario</label>
              <select
                class="form-control"
                name=""
                id=""
                @change="changeCategory($event)"
              >
                <option
                  v-for="item in arrCategory"
                  :value="item.id"
                  :key="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>

            <div class="form-group col-md-7">
              <label for="">Paquetes</label>
              <select
                class="form-control"
                name=""
                id=""
                @change="changeSubCategory($event)"
              >
                <option
                  v-for="item in arrSubCategory"
                  :value="item.id"
                  :key="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>

            <div class="form-group col-md-7">
              <label for="">Seleccione una suscripción</label>
              <select class="form-control" @change="changeSuscription($event)">
                <option value="">--Seleccionar--</option>
                <option
                  v-for="(item, index) in suscripciones"
                  :value="index + 1"
                  :key="item.id"
                >
                  {{ item.product }} - {{ item.id }}
                </option>
              </select>
            </div>
            <!-- container__detalle  v-if="suscriptionSelected['productos']"  -->
            <div>
              <!-- Fecha de Pago -->
              <div class="form-group col-md-7">
                <label for="">Fecha de pago</label>
                <p
                  v-if="isFechaPago"
                  style="
                    color: red;
                    font-size: 0.8em;
                    margin: 0 0 5px 0;
                    padding: 0;
                  "
                >
                  (*)Falta colocar la fecha de pago
                </p>
                <input
                  disabled
                  id="fechaPago"
                  v-model="fechaPago"
                  type="date"
                  class="form-control"
                />
              </div>
              <!-- Monto Pagado -->
              <div class="form-group col-md-7">
                <label for="">Monto pagado</label>
                <p
                  v-if="isMontoPagado"
                  style="
                    color: red;
                    font-size: 0.8em;
                    margin: 0 0 5px 0;
                    padding: 0;
                  "
                >
                  (*)Falta Monto Pagado
                </p>
                <input
                  @keypress="validateNumberAndPoint($event)"
                  v-model="montoPagado"
                  type="text"
                  class="form-control"
                  placeholder="50.90"
                  id="montoPagado"
                  disabled
                />
              </div>
              <!-- Numero de tarjeta -->
              <div class="form-group col-md-7">
                <label for="">Número de Tarjeta</label>
                <div class="row">
                  <div class="col-md-6">
                    <p
                      v-if="isNumeroTarjetaInicial"
                      style="
                        color: red;
                        font-size: 0.8em;
                        margin: 0 0 5px 0;
                        padding: 0;
                      "
                    >
                      (*)El campo esta vacio
                    </p>
                    <input
                      v-model="numeroTarjetaInicial"
                      placeholder="Primeros 6 digitos de tu tarjeta"
                      @keypress="validateNumber($event)"
                      maxlength="6"
                      type="text"
                      class="form-control"
                      id="numeroTarjetaInicial"
                      disabled
                    />
                  </div>
                  <div class="col-md-6">
                    <p
                      v-if="isNumeroTarjetaFinal"
                      style="
                        color: red;
                        font-size: 0.8em;
                        margin: 0 0 5px 0;
                        padding: 0;
                      "
                    >
                      (*)El campo esta vacio
                    </p>
                    <input
                      v-model="numeroTarjetaFinal"
                      placeholder="Ultimos 4 digitos de tu tarjeta"
                      @keypress="validateNumber($event)"
                      maxlength="4"
                      type="text"
                      class="form-control"
                      id="numeroTarjetaFinal"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <!-- Constancia de Cobro -->
              <div class="form-group col-md-7">
                <label for="">Constancia de cobro (imagen, pdf)</label>
                <p
                  v-if="isConstanciaCobro"
                  style="
                    color: red;
                    font-size: 0.8em;
                    margin: 0 0 5px 0;
                    padding: 0;
                  "
                >
                  (*)Adjunte su archivo
                </p>
                <input
                  id="updateFile"
                  disabled
                  @change="updateFile"
                  name="archivo"
                  type="file"
                  class="form-control"
                />
              </div>
              <!-- Boton  -->
              <div class="form-group col-md-7">
                <button
                  id="butonRegistar"
                  disabled
                  type="button"
                  class="btn-comercio btn__ingresar"
                  @click="registerDobleCobro()"
                >
                  Enviar
                </button>
              </div>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <img class="avatar__alicia" src="../../assets/img/nueva_alicia_pages_all.png" />
    <img class="avatar__alicia__mobile" src="../../assets/img/nueva_alicia_pages_all.png" />
  </div>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Cards",
  data: function () {
    return {
      /* Aca variables incorporadas */
      isFechaPago: false,
      isMontoPagado: false,
      isNumeroTarjetaInicial: false,
      isNumeroTarjetaFinal: false,
      isConstanciaCobro: false,

      fechaPago: "",
      montoPagado: "",
      numeroTarjetaInicial: "",
      numeroTarjetaFinal: "",
      constanciaCobro: "",
      fileBase64: "",

      /* final de variables incorporadas */
      suscripciones: [],
      nameSuscription: "Aún no ha seleccionado una suscripción",
      suscriptionSelected: {},
      categoryId: "",
      subCategoryId: "",
      mensaje: "",
      selectedCategoria: "0",
      positionId: "",
      show: true,
      isCollapsed: true,
      isLoading: false,
      fullPage: true,
      arrCategory: [
        { id: "", name: "--Seleccionar--" },
        { id: "1", name: "El Comercio" },
        { id: "2", name: "Gestión" },
      ],
      arrSubCategory: [{ id: "", name: "--Seleccionar--" }],
    };
  },
  components: {
    Loading,
  },
  methods: {
    /* TODO: Codificando el archivo en Base64*/
    async updateFile(e) {
      let vm = this;
      const file = e.target.files[0];
      let fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        vm.fileBase64 = fileReader.result;
        console.log(file);
      };
    },
    /* TODO:validar numero */
    validateNumber(event) {
      let val = event.key;
      let myRex = new RegExp("^[0-9]$");
      if (!myRex.test(val)) event.preventDefault();
    },
    /* TODO:validar tamaño del numero */
    validateNumberAndPoint(event) {
      let val = event.key;
      let myRex = new RegExp("^[0-9.]$");
      if (!myRex.test(val)) event.preventDefault();
    },
    changeCategory(event) {
      let vm = this;
      let id = event.target.value;
      vm.categoryId = id;
      vm.isLoading = true;

      if (id == "") {
        vm.arrSubCategory = [{ id: "", name: "--Seleccionar--" }];
      } else if (id == "1") {
        vm.arrSubCategory = [
          { id: "", name: "--Seleccionar--" },
          { id: "1", name: "El Comercio" },
          { id: "2", name: "El Comercio Impreso + Digital" },
          { id: "3", name: "El Comercio Digital" },
        ];
      } else if (id == "2") {
        vm.arrSubCategory = [
          { id: "", name: "--Seleccionar--" },
          { id: "4", name: "Gestión" },
          { id: "5", name: "Gestión Impreso + Digital" },
          { id: "6", name: "Gestión Digital" },
        ];
      }
      vm.suscripciones = [];
      vm.suscriptionSelected = {};
      vm.isLoading = false;
      vm.nameSuscription = "Aún no ha seleccionado una suscripción";

      vm.isLoading = false;
    },
    changeSubCategory(event) {
      let vm = this;
      let id = event.target.value;
      vm.subCategoryId = id;
      vm.isLoading = true;

      vm.suscripciones = [];
      vm.suscriptionSelected = {};
      vm.nameSuscription = "Aún no ha seleccionado una suscripción";

      if (id == "") {
        vm.isLoading = false;
      } else {
        let data = {
          nro_documento: localStorage.getItem("numeroDocumento"),
          tipo_documento: localStorage.getItem("tipoDocumento"),
          categoria: vm.categoryId,
          subcategoria: id,
          canal: "7",
        };

        axios
          .post(`${process.env.VUE_APP_ROOT_API}/listSuscripciones`, data)
          .then(function (res) {
            vm.isLoading = false;
            if (res.data.data.code == "1") {
              vm.suscripciones = res.data.data.landing;
            } else {
              vm.isLoading = false;
              vm.$fire({
                title: "Información",
                text: "No tiene suscripciones en este paquete.",
                type: "info",
                timer: 4000,
              }).then((r) => {});
            }
          })
          .catch((error) => {
            vm.isLoading = false;
            vm.$fire({
              title: "Error",
              text: "No tiene suscripciones en este paquete.",
              type: "error",
              timer: 4000,
            }).then((r) => {});
          });
      }
    },
    changeSuscription(event) {
      let vm = this;
      let id = event.target.value;
      vm.isLoading = false;
      if (id) {
        document.getElementById("butonRegistar").disabled = false;
        document.getElementById("updateFile").disabled = false;
        document.getElementById("numeroTarjetaInicial").disabled = false;
        document.getElementById("numeroTarjetaFinal").disabled = false;
        document.getElementById("fechaPago").disabled = false;
        document.getElementById("montoPagado").disabled = false;
        vm.positionId = id;
      } else {
        vm.isLoading = true;
        document.getElementById("butonRegistar").disabled = true;
        document.getElementById("updateFile").disabled = true;
        document.getElementById("numeroTarjetaInicial").disabled = true;
        document.getElementById("numeroTarjetaFinal").disabled = true;
        document.getElementById("fechaPago").disabled = true;
        document.getElementById("montoPagado").disabled = true;
      }
    },
    registerDobleCobro() {
      let vm = this;

      vm.isFechaPago = false;
      vm.isMontoPagado = false;
      vm.isNumeroTarjetaInicial = false;
      vm.isNumeroTarjetaFinal = false;

      if (vm.fechaPago == "") {
        vm.isFechaPago = true;
      }

      if (vm.montoPagado == "") {
        vm.isMontoPagado = true;
      }

      if (vm.numeroTarjetaInicial == "") {
        vm.isNumeroTarjetaInicial = true;
      }
      if (vm.numeroTarjetaFinal == "") {
        vm.isNumeroTarjetaFinal = true;
      }

      if (
        vm.isFechaPago == false &&
        vm.isMontoPagado == false &&
        vm.isNumeroTarjetaInicial == false &&
        vm.isNumeroTarjetaFinal == false &&
        vm.isConstanciaCobro == false
      ) {
        this.nrodoc = this.$route.params.nrodoc;
        this.typedoc = this.$route.params.typedoc;
        this.positionid = this.$route.params.positionid;
        this.token = this.$route.params.token;

        let data = {
          constanciaCobro: vm.fileBase64,
          document_type: localStorage.getItem("tipoDocumento"),
          document_number: localStorage.getItem("numeroDocumento"),
          positionId: vm.positionId,
          fechaPago: vm.fechaPago,
          montoPagado: vm.montoPagado,
          numeroTarjetaInicial: vm.numeroTarjetaInicial,
          numeroTarjetaFinal: vm.numeroTarjetaFinal,
          token: "",
        };
        //console.log("Data =>", data);
        axios
          .post(`${process.env.VUE_APP_ROOT_API}/complaints-doblecobro`, data)
          .then(function (res) {
            vm.isLoading = false;
            if (res.data.data.code == 1) {
              vm.$fire({
                title: "Constancia de reclamo enviada",
                html: `${res.data.data.message} <br/><br/> Gracias por escribirnos.`,
                type: "success",
                timer: 6000,
              }).then((r) => {});

              vm.fechaPago = "";
              vm.montoPagado = "";
              vm.numeroTarjetaInicial = "";
              vm.numeroTarjetaFinal = "";
              vm.constanciaCobro = "";
            } else {
              vm.$fire({
                title: "Seguridad",
                html: `No se registro su solicitud, Intentelo más tarde o llame al 311-5000`,
                type: "info",
                timer: 6000,
              }).then((r) => {
                console.log("dismin", r);
              });
            }
          })
          .catch((error) => {
            vm.isLoading = false;
            vm.$fire({
              title: "Error",
              text: "Ocurrio un error en el servidor" + error.message,
              type: "error",
              timer: 6000,
            }).then((r) => {
              console.log(r);
            });
          });
      }
    },
  },
  filters: {
    currency(value) {
      return `S/ ${value}`;
    },
  },
  mounted() {
    let vm = this;
    axios.interceptors.request.use(
      (config) => {
        vm.isLoading = true;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    $("#pgb-container").hide();
  },
};
</script>
